import AuthLayout from "@/components/Auth/AuthLayout";
import AuthLinks from "@/components/Auth/AuthLinks";
import SignUpForm from "@/components/Auth/SignUpForm";
import { ChefHat } from "@/components/Icons";
import MobLogotype from "@/components/MobLogotype";
import { useAdContext } from "@/contexts/AdContext";
import craft from "@/lib/craft";
import getCommonStaticProps from "@/lib/getCommonStaticProps";
import { BookmarkIcon, CalendarDaysIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";
import { motion } from "framer-motion";
import gql from "graphql-tag";
import Head from "next/head";
import { useEffect, useState } from "react";
import { Seomatic } from "react-seomatic";

const queryDocument = gql`
  query QuerySignUp {
    pageEntry: entry(section: "signUp") {
      ... on signUp_signUp_Entry {
        id
        heading
        introduction
        features {
          ... on features_feature_BlockType {
            id
            label
            icon
          }
        }
      }
      seomatic(asArray: true) {
        metaTitleContainer
        metaTagContainer
        metaLinkContainer
        metaScriptContainer
        metaJsonLdContainer
      }
    }
  }
`;

export async function getStaticProps({ params, previewData, preview }) {
  // Fetch common static properties
  const commonStaticProps = await getCommonStaticProps();

  const queries = [
    {
      document: queryDocument,
    },
  ];

  const response = await craft(queries, preview ? previewData.token : null);

  const { pageEntry } = response[0];

  return {
    props: {
      ...commonStaticProps,
      pageEntry,
    },
    revalidate: process.env.VERCEL_ENV !== "production" ? 1 : 3600,
  };
}

const featureIcons = {
  ChefHat,
  CalendarDaysIcon,
  BookmarkIcon,
};

const AuthSignUp = ({ pageEntry }) => {
  // Set showAds to true for this page
  const { setShowAds } = useAdContext();
  useEffect(() => setShowAds(false));

  const [state, setState] = useState("idle");

  const showHeader =
    state === "idle" || state === "exists" || state === "checking";

  return (
    <>
      <Seomatic Head={Head} {...pageEntry.seomatic} />
      <div className={clsx("flex w-full flex-col")}>
        {showHeader && (
          <div
            className={clsx("space-y-6 p-6 max-lg:mt-auto lg:p-12 lg:pt-32")}
          >
            <div className={clsx("w-44")}>
              <MobLogotype />
            </div>
            <div
              className={clsx("text-balance text-xl")}
              dangerouslySetInnerHTML={{ __html: pageEntry.introduction }}
            ></div>
            <div className={clsx("space-y-3")}>
              {pageEntry.features.map((feature, i) => {
                const Icon = featureIcons[feature.icon];

                return (
                  <motion.div
                    key={feature.label}
                    className={clsx("flex items-center space-x-4")}
                    initial={{ opacity: 0, y: "0.5rem" }}
                    animate={{ opacity: 1, y: "0rem" }}
                    transition={{
                      duration: 0.5,
                      delay: 0.5 + 0.1 * i,
                      ease: "circOut",
                    }}
                  >
                    <div className={clsx("text-zinc-50")}>
                      <Icon className={clsx("h-5 w-5")} />
                    </div>
                    <div className={clsx("font-body text-sm")}>
                      {feature.label}
                    </div>
                  </motion.div>
                );
              })}
            </div>
          </div>
        )}
        <div className={clsx("p-6 lg:p-12 lg:pt-32")}>
          {!showHeader && (
            <div className={clsx("mb-6")}>
              <h1 className={clsx("heading-sm--spatial")}>
                {pageEntry.heading}
              </h1>
            </div>
          )}
          <div className={clsx("max-w-screen-sm")}>
            <SignUpForm onStatusChange={setState} />
          </div>

          <div>
            <AuthLinks />
          </div>
        </div>
      </div>
    </>
  );
};

AuthSignUp.getLayout = (page) => {
  const { props } = page;
  const { notifications, navigation, colourway } = props;

  return (
    <AuthLayout
      notifications={notifications}
      colourway={colourway}
      navigation={navigation}
    >
      {page}
    </AuthLayout>
  );
};

export default AuthSignUp;
